import Heading from 'components/Heading/Heading';
import TextLink from 'components/TextLink/TextLink';
import { useQuery } from 'react-query';
import { NowRelevant } from 'types/dataApi';
import { getRelevantWeatherThumbnails } from 'utils/getRelevant';

import styles from './RelevantWeatherThumbnails.module.scss';

export default function RelevantWeatherThumbnails() {
	const { data } = useQuery(
		['relevantWeatherThumbnails'],
		() => getRelevantWeatherThumbnails(),
		{
			retry: false,
		}
	);

	return (
		<section className={styles.relevantWeatherThumbnailsContainer}>
			<Heading
				variant="h2"
				classNameExt={styles.relevantWeatherThumbnailsHeading}
			>
				Nu relevant
			</Heading>
			<div className={styles.relevantWeatherThumbnails}>
				<RelevantWeatherThumbnailItems data={data} />
			</div>
		</section>
	);
}

function RelevantWeatherThumbnailItems({
	data,
}: {
	data: NowRelevant | undefined;
}) {
	if (!data) return null;

	return (
		<>
			{data.map((relevantWeatherItem) => {
				const backgroundImage = relevantWeatherItem?.backgroundUrl;
				const hasBackgroundImage = backgroundImage ? true : false;
				const isPortrait = isPortaitImage(relevantWeatherItem.imageUrl);
				const imageClass = hasBackgroundImage
					? `${styles.relevantWeatherThumbnailImage} ${styles.transparentImage}`
					: styles.relevantWeatherThumbnailImage;
				return (
					<div
						key={relevantWeatherItem.url}
						className={styles.relevantWeatherThumbnailItem}
					>
						<a
							href={relevantWeatherItem.url}
							className={styles.relevantWeatherThumbnailItemLink}
						>
							<img
								src={relevantWeatherItem.imageUrl}
								alt={relevantWeatherItem.label}
								loading="lazy"
								className={imageClass}
								data-isportait={isPortrait}
							/>
							{backgroundImage ? (
								<img
									src={backgroundImage}
									alt=""
									loading="lazy"
									className={`${styles.relevantWeatherThumbnailImage}
                    ${styles.relevantWeatherBackgroundImage}
                  `}
									data-isportait={isPortrait}
								/>
							) : null}
						</a>
						<TextLink
							appearance="text"
							href={relevantWeatherItem.url}
						>
							{relevantWeatherItem.label}
						</TextLink>
					</div>
				);
			})}
		</>
	);
}

function isPortaitImage(src) {
	try {
		const imageUrl = new URL(src);
		const imageParams = new URLSearchParams(imageUrl.search);
		const imageHeight = imageParams.get('height') ?? 0;
		const imageWidth = imageParams.get('width') ?? 0;

		return imageHeight > imageWidth ? true : false;
	} catch (e) {
		return false;
	}
}
