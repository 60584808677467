import axios from 'redaxios';
import getConfig from 'next/config';
import { NowRelevant } from 'types/dataApi';

const { publicRuntimeConfig } = getConfig();
const { siteName } = publicRuntimeConfig;

export async function getRelevantWeatherThumbnails(): Promise<NowRelevant> {
  const response = await axios.get(
    `https://data.buienradar.nl/1.0/nowrelevant/${siteName}`
  );
  return response.data;
}
